import { gql } from 'apollo-boost'

export const APPLY_FOR_DOG = gql`
  mutation createDogApplication(
    $dogApplication: CreateDogApplication
    $paymentToken: String
  ) {
    createDogApplication(
      dogApplication: $dogApplication
      paymentToken: $paymentToken
    ) {
      ... on DogApplication {
        id
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const CANCEL_DOG_APPLICATION = gql`
  mutation cancelDogApplication($dogApplication: CancelDogApplication) {
    cancelDogApplication(dogApplication: $dogApplication) {
      ... on DogApplication {
        id
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const READ_DOG_APPLICATION = gql`
  mutation readDogApplication($dogApplication: ReadDogApplication) {
    readDogApplication(dogApplication: $dogApplication) {
      ... on DogApplication {
        id
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const APPROVE_DOG_APPLICATION = gql`
  mutation approveDogApplication($dogApplication: ApproveDogApplication) {
    approveDogApplication(dogApplication: $dogApplication) {
      ... on DogApplication {
        id
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const REJECT_DOG_APPLICATION = gql`
  mutation rejectDogApplication($dogApplication: RejectDogApplication) {
    rejectDogApplication(dogApplication: $dogApplication) {
      ... on DogApplication {
        id
      }
      ... on Error {
        errorMessage
      }
    }
  }
`
export const DELETE_DOG_APPLICATION = gql`
  mutation deleteDogApplication($dogApplication: DeleteDogApplication) {
    deleteDogApplication(dogApplication: $dogApplication) {
      ... on DogApplication {
        id
      }
      ... on Error {
        errorMessage
      }
    }
  }
`
export const CONFIRM_DEPARTURE_TRAVEL = gql`
  mutation confirmDepartureTravel($dogApplication: ConfirmDepartureTravel) {
    confirmDepartureTravel(dogApplication: $dogApplication) {
      ... on DogApplication {
        id
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const PROPOSE_DOG_APPLICATION_SHIPPING_PRICE = gql`
  mutation proposeShippingPrice($dogApplication: ProposeShippingPrice) {
    proposeShippingPrice(dogApplication: $dogApplication) {
      ... on DogApplication {
        id
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const PAY_DOG_APPLICATION = gql`
  mutation payDogApplication(
    $dogApplication: PayDogApplication
    $paymentType: String
  ) {
    payDogApplication(
      dogApplication: $dogApplication
      paymentType: $paymentType
    ) {
      ... on DogApplication {
        id
      }
      ... on Error {
        errorMessage
      }
    }
  }
`
export const CREATE_STRIPE_PAYMENT_INTENT = gql`
  mutation createStripePaymentIntent(
    $dogApplication: CreateStripePaymentIntent
    $shipping: CreateStripePaymentIntentShipping
    $paymentType: String
  ) {
    createStripePaymentIntent(
      dogApplication: $dogApplication
      shipping: $shipping
      paymentType: $paymentType
    ) {
      ... on StripeIntent {
        clientSecret
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const FINALIZE_STRIPE_PAYMENT_INTENT = gql`
  mutation finalizeStripePaymentIntent(
    $dogApplication: FinalizeStripePaymentIntent
    $paymentType: String
    $paymentMethodType: String
  ) {
    finalizeStripePaymentIntent(
      dogApplication: $dogApplication
      paymentType: $paymentType
      paymentMethodType: $paymentMethodType
    ) {
      ... on DogApplication {
        id
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const CREATE_PAYPAL_ORDER = gql`
  mutation createPayPalOrder(
    $dogApplication: CreatePayPalOrder
    $paymentType: String
  ) {
    createPayPalOrder(
      dogApplication: $dogApplication
      paymentType: $paymentType
    ) {
      ... on PayPalOrder {
        id
      }
      ... on CreatePayPalOrderError {
        errorId
        errorMessage
      }
    }
  }
`

export const APPROVE_PAYPAL_ORDER = gql`
  mutation approvePayPalOrder(
    $dogApplication: ApprovePayPalOrder
    $paymentType: String
    $orderId: String
  ) {
    approvePayPalOrder(
      dogApplication: $dogApplication
      paymentType: $paymentType
      orderId: $orderId
    ) {
      ... on PayPalOrder {
        id
      }
      ... on ApprovePayPalOrderError {
        errorId
        errorMessage
      }
    }
  }
`

export const SET_PAYMENT_DUE_DATE = gql`
  mutation setPaymentDueDate($order: SetPaymentDueDate) {
    setPaymentDueDate(order: $order) {
      ... on Order {
        id
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const UPDATE_SHIPPING_STATUS = gql`
  mutation updateShippingStatus($order: UpdateShippingStatus) {
    updateShippingStatus(order: $order) {
      ... on Order {
        id
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const SET_SHIPPMENT_RECIEVED = gql`
  mutation shippmentRecieved($order: ShippingRecieved) {
    shippmentRecieved(order: $order) {
      ... on Order {
        id
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const GET_DOG_APPLICATIONS = gql`
  query getDogApplications($id: Int) {
    dogApplications(id: $id) {
      id
      dogId
      breederId
      shippingOptionId
      orderId
      read
      approved
      canceled
      deleted
      rejectionReason
      rejectionComments
      frozen
      age
      hasKids
      kidsAge
      otherPets
      firstDog
      caringKnowledge
      timeSpent
      homeType
      goodFinancing
      longTermPet
      primaryCaretaker
      choiceReason
      otherPetsTypes
      extraTime
      otherInformation
      caringPlanned
      minEstimatedShippingPrice
      maxEstimatedShippingPrice
      proposedShippingPrice
      proposedShippingPriceAccepted
      shippingType
      zipcode
      name
      lastName
      address
      city
      country
      state
      email
      phoneNumber
      textOk
      shippingEstimate
      calculateShippingPrice
      totalEstimate
      fee
      brilliantPadType
      brilliantPadPrice
      createdAt
      discount(active: true) {
        id
        amount
        code
        applyDiscountByDefault
        isAmbassadorCode
      }
      order {
        id
        read
        received
        shippingProvider
        shippingProviderPhone
        trackingNumber
        shippingDate
        shippingTime
        shippingNotes
        shipmentStatus
        stripeTransferId
        active
        updatedAt
        paymentDueDate
        payments {
          id
          amount
          type
          status
          breadTransactionId
        }
      }
      shippingOption {
        type
      }
      breeder {
        id
        companyName
        firstName
        lastName
        phone
        state
        country
        companyLogo {
          small
        }
      }
      customer {
        id
        firstName
        lastName
        email
        zipcode
        state
        country
        picture {
          small
        }
      }
      dog {
        id
        isAvailable
        isDeleted
        isSold
        name
        price
        currency
        description
        gender
        discount(active: true) {
          id
          applyDiscountByDefault
          code
          amount
        }
        profilePicture {
          id
          small
        }
        breed {
          id
          name
          discount(active: true) {
            id
            applyDiscountByDefault
            code
            amount
          }
        }
        salePrice {
          price
          deposit
          transaction
          fapFee
          subTotal
          total
          reminder
          puppyPrice
        }
        litter {
          birthDate
        }
      }
    }
  }
`

export const UPDATE_DOG_APPLICATION = gql`
  mutation updateDogApplication($dogApplication: UpdateDogApplication) {
    updateDogApplication(dogApplication: $dogApplication) {
      ... on DogApplication {
        id
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

export const GET_DOG_APPLICATIONS_FOR_ADMIN = gql`
  query getDogApplications($id: Int, $cursor: String) {
    dogApplicationsForAdmin(id: $id, cursor: $cursor) {
      results {
        id
        shippingType
        approved
        dog {
          name
          profilePicture {
            small
          }
        }
        customer {
          firstName
          lastName
        }
        breeder {
          firstName
          lastName
          companyName
        }
        order {
          shipmentStatus
          payments {
            type
            status
          }
        }
      }
      cursors {
        hasNext
        nextCursor
      }
    }
  }
`

export const GET_APPLICATION_SHIPPING_OPTIONS = gql`
  query getShippingOptions($dogId: Int, $zipcode: String) {
    shippingOptions(dogId: $dogId, zipcode: $zipcode) {
      type
      min
      max
      nearby
      available
      error
    }
  }
`

export const GET_CUSTOMER_APPLICATIONS = gql`
  query getCustomerData($id: Int) {
    customerApplications(id: $id) {
      dogId
      customerId
    }
  }
`

export const GET_DELIVERY_OPTIONS = gql`
  query customerDeliveryOptions($dogId: Int, $zipcode: String) {
    customerDeliveryOptions(zipcode: $zipcode, dogId: $dogId) {
      title
      slug
      description
      amount
      available
      errorMessage
    }
  }
`
